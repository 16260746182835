import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { TextGeometry } from 'three/addons/geometries/TextGeometry.js';
import { FontLoader } from 'three/addons/loaders/FontLoader.js';

/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

const loadingManager = new THREE.LoadingManager()
loadingManager.onLoaded = () =>{
    console.log('loaded')
}
loadingManager.onError = () =>{
    console.log('Error')
}

const textureLoader = new THREE.TextureLoader(loadingManager)
const texture = textureLoader.load('/textures/logo.png')

/**
 * Object
 */
const geometry = new THREE.BoxGeometry(1, 1, 1)
const material = new THREE.MeshBasicMaterial({ map:texture })
const mesh = new THREE.Mesh(geometry, material)
//scene.add(mesh)
mesh.rotation.x = 0.5

// Sphere stars
const sphereGeo = new THREE.SphereGeometry(0.1, 8, 8)
const sphereMaterial = new THREE.MeshNormalMaterial()
const stars = new THREE.Mesh( sphereGeo, sphereMaterial )

const starArray = [];
let space = {
    dimensions:{
      x:100,
      y:100,
      z:100,
    },
    createStar:function(){
      let obj = new THREE.Mesh( sphereGeo, sphereMaterial )
      let coordinates = this.getRandCoordinates()
      let velocity = this.getRandMovement()
      for(let vect in coordinates){
        obj.position[vect] = coordinates[vect]
      }
      obj["movement"] = {}
      for(let vect in velocity){
        obj.movement[vect] = velocity[vect]
      }
      return obj
    },
    getLifespan:function(){
      return Math.floor(Math.random() * 20000 + 10000) //ms
    },
    getRandMovement:function(){
      let vectorV = {
        x:0,
        y:0,
        z:0,
      }
      for(let vect in vectorV){
        let randNum = Math.floor(Math.random() - 0.5)/100
        if(randNum < 0){
          randNum -= 0.005
        }else if(randNum > 0){
          randNum += 0.005
        }else if(randNum == 0){
          randNum = 0.005
        }
        vectorV[vect] = randNum
      }
      return vectorV
    },
    getRandCoordinates:function(){
      let vector = {
        x:0,
        y:0,
        z:0,
      }
      for(let vect in this.dimensions){
        vector[vect] = Math.floor(Math.random() * this.dimensions[vect] * 100)/100 - (this.dimensions[vect]/2)
      }
      return vector
    },
  }
  const starCount = 1000

  for(let i=0;i<starCount;i++){
    starArray.push(space.createStar())
    scene.add(starArray[i])
  }
  console.log(starArray)


const textmaterial = new THREE.MeshNormalMaterial()
const loader = new FontLoader();
let textGeo
let textMesh = new THREE.Mesh()
let font = undefined



    loader.load( '/helvetiker_regular.typeface.json', function ( response ) {
        font = response
        console.log(font)
        createText()
        
    } );

    function createText() {
        console.log('create text')
        textGeo = new TextGeometry( 'pinkstar*', {
            font: font,
            size: 0.4,
            height: 0.1,
            bevelSize: 0.03,
            bevelThickness: 0.03,
			bevelEnabled: true,
            bevelSegments: 10

        } );
        
        textGeo.computeBoundingBox();

        const centerOffset = - 0.5 * ( textGeo.boundingBox.max.x - textGeo.boundingBox.min.x );
        textGeo.translate(centerOffset,0,0)
        textMesh = new THREE.Mesh(textGeo,textmaterial)
        
        //textMesh.position.x = centerOffset;
        //textMesh.centerOffset = -2
	    //textMesh.position.y = -0.2;
	    //textMesh.position.z = -2;

	    //textMesh.rotation.x = 0;
	    //textMesh.rotation.y = Math.PI * 2;

        scene.add(textMesh)
        
    }


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Fullscreen
 */
window.addEventListener('dblclick', () =>
{
    const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement

    if(!fullscreenElement)
    {
        if(canvas.requestFullscreen)
        {
            canvas.requestFullscreen()
        }
        else if(canvas.webkitRequestFullscreen)
        {
            canvas.webkitRequestFullscreen()
        }
    }
    else
    {
        if(document.exitFullscreen)
        {
            document.exitFullscreen()
        }
        else if(document.webkitExitFullscreen)
        {
            document.webkitExitFullscreen()
        }
    }
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(80, sizes.width / sizes.height, 0.1, 100)
camera.position.z = 4
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    controls.update()
    mesh.rotation.y = clock.elapsedTime
    textMesh.rotation.y = Math.sin(clock.elapsedTime)
    textMesh.rotation.z = Math.sin(clock.elapsedTime)*0.2
    // Render
    renderer.render(scene, camera)
    for(let i=0;i<starArray.length;i++){
        let moveVector = starArray[i]
        for(let vect in moveVector.movement){
          moveVector.position[vect] += moveVector.movement[vect]
        }
      }
    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()